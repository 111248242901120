const dayOptions = [
    {value: '1', label: 'Today'},
    {value: '3', label: 'Last 3 days'},
    {value: '7', label: 'Last 7 days'},
    {value: '30', label: 'Last month'},
    {value: '60', label: 'Last 2 months'},
    {value: '90', label: 'Last 3 months'},
    {value: '180', label: 'Last 6 months'},
    {value: '365', label: 'Last year'},
];
/**
 * Some of these options will render, but likely crash the browser.
 *
 * @type {[{label: string, value: string},{label: string, value: string},{label: string, value: string},{label: string,
 *   value: string},{label: string, value: string},null,null]}
 */
const groupByOptions = [
    {value: 'continent', label: 'Continent'},
    // {value: 'country', label: 'Country'},
    // {value: 'region', label: 'Region'},
    // {value: 'city', label: 'City'},
    // {value: 'tld', label: 'Top Level Domain'},
    {value: 'browser', label: 'Browser'},
    // {value: 'browserVersion', label: 'Browser Version'},
    {value: 'deviceType', label: 'Device Type'},
    // {value: 'deviceVendor', label: 'Device Vendor'},
    // {value: 'deviceModel', label: 'Device Model'},
    {value: 'eDomain', label: 'Email Domain'},
    {value: 'os', label: 'OS'},
    // {value: 'osVersion', label: 'OS Version'},
    // {value: 'errors', label: 'Errors'},
    // {value: 'botScore', label: 'Bot Score'},
    {value: 'slowestComponent', label: 'Slowest Component'},
];
const filterByOptions = [
    {value: 'continent', label: 'Continent'},
    {value: 'country', label: 'Country'},
    {value: 'region', label: 'Region'},
    {value: 'city', label: 'City'},
    {value: 'tld', label: 'Top Level Domain'},
    {value: 'browser', label: 'Browser'},
    {value: 'browserVersion', label: 'Browser Version'},
    {value: 'deviceType', label: 'Device Type'},
    {value: 'deviceVendor', label: 'Device Vendor'},
    {value: 'deviceModel', label: 'Device Model'},
    {value: 'os', label: 'OS'},
    {value: 'osVersion', label: 'OS Version'},
    {value: 'errors', label: 'Errors'},
    {value: 'botScore', label: 'Bot Score'},
    {value: 'slowestComponent', label: 'Slowest Component'},
];

export {dayOptions, groupByOptions, filterByOptions};