// FpPage.js
import React from 'react';
import Analytics from './Analytics'; // Adjust the import path as necessary
import {Link} from 'react-router-dom';
import Controls from './Controls';
import {groupByOptions} from './Options';

const FingerprintPage = () => {
    const defaultDays = window.localStorage.getItem('days') || '7';
    const defaultGroupBy = window.localStorage.getItem('groupBy') || '';
    const defaultFilterBy = window.localStorage.getItem('filterBy') || '';

    return (
        <div className="App">
            <Link to={'/'} className="App-link home">Home</Link>

            <header className="App-hero">
                <h1>Fingerprint Analytics</h1>
            </header>

            <Controls/>

            <h2>Stability</h2>
            <Analytics title="Component Changes"
                       description="Changes found per component over time between hourly tests"
                       days={defaultDays}
                       groupBy={defaultGroupBy} groupByOptions={groupByOptions} filterBy={defaultFilterBy}
                       path="/api/experiments/fp?metric=stability"/>

            <hr/>
            <h2>Latency</h2>
            <Analytics title="Slowest Components"
                       description="Number of times a component was marked as the slowest per fingerprint"
                       days={defaultDays}
                       groupBy={defaultGroupBy}
                       groupByOptions={groupByOptions}
                       filterBy={defaultFilterBy}
                       path="/api/experiments/fp?metric=slowest"/>

            <Analytics title="Total Duration" description="Total Duration of all components per fingerprint"
                       days={defaultDays}
                       groupBy={defaultGroupBy}
                       groupByOptions={groupByOptions}
                       filterBy={defaultFilterBy}
                       path="/api/experiments/fp?metric=duration"/>

            <Analytics title="Peak Calculations"
                       description="Sum of component calculation times by component, including peaks"
                       days={defaultDays}
                       groupBy={defaultGroupBy}
                       groupByOptions={groupByOptions}
                       filterBy={defaultFilterBy}
                       path="/api/experiments/fp?metric=peaks"/>

            <hr/>
            <h2>Acuity</h2>
            <Analytics title="Component Collisions" description="Approximate collisions per day by type of detection"
                       days={defaultDays}
                       groupBy={defaultGroupBy}
                       groupByOptions={groupByOptions}
                       filterBy={defaultFilterBy}
                       path="/api/experiments/fp?metric=acuity"/>
        </div>
    );
};

export default FingerprintPage;
