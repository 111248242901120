// Simple react page, with an email field and a submit button
import React from 'react';
import {Link} from 'react-router-dom';
// import Select from "react-select";
import Analytics from "./Analytics";
// import {filterByOptions, groupByOptions, dayOptions} from './Options';
import Controls from "./Controls";

const EmailPage = () => {
    const defaultDays = window.localStorage.getItem('days') || '7';
    const defaultGroupBy = window.localStorage.getItem('groupBy') || '';
    const defaultFilterBy = window.localStorage.getItem('filterBy') || '';

    return (
        <div className="App">
            <Link to={'/'} className="App-link home">Home</Link>
            <header className="App-hero">
                <h1>Email Analytics</h1>
            </header>

            <Controls/>

            <h2>Quantity</h2>
            <Analytics title="Emails Detected" description="Unique emails hashes detected"
                       days={defaultDays}
                       filterBy={defaultFilterBy}
                       groupBy={defaultGroupBy}
                       path="/api/experiments/fp?metric=emails"/>

            <h2>Domains</h2>
            <Analytics title="Emails by Domain" description="Unique email domains"
                       days={defaultDays}
                       filterBy={defaultFilterBy}
                       groupBy={defaultGroupBy}
                       path="/api/experiments/fp?metric=emailDomains"/>

            <h2>Age</h2>
            <Analytics title="Emails by Age" description="Average email age in minutes since first seen"
                       days={defaultDays}
                       filterBy={defaultFilterBy}
                       groupBy={defaultGroupBy}
                       path="/api/experiments/fp?metric=emailAge"/>

            <h2>Count</h2>
            <Analytics title="Emails per Device" description="Average number of emails detected per device"
                       days={defaultDays}
                       filterBy={defaultFilterBy}
                       groupBy={defaultGroupBy}
                       path="/api/experiments/fp?metric=emailCount"/>

            <h2>Email input test fields</h2>
            <p>
                <input type="text" placeholder="email"/>
                <input type="email"/>
                <input type="text" id="email"/>
                <input type="text" name="email"/>
                <input type="text" name="email2"/>
                <input type="text" pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"/>
                <input autoComplete="email"/>
                <input type="email" autoComplete="email"/>
            </p>

        </div>
    );
};

export default EmailPage;