import Select from "react-select";
import {dayOptions, filterByOptions, groupByOptions} from "./Options";
import React from "react";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const Controls = () => {
    const defaultDays = window.localStorage.getItem('days') || '7';
    const defaultGroupBy = window.localStorage.getItem('groupBy') || '';
    const defaultFilterBy = window.localStorage.getItem('filterBy') || '';
    const defaultFilterByValue = window.localStorage.getItem('filterByValue') || '';
    return (
        <div className="App-controls">
            <label htmlFor="filterBy">Filter By</label>
            <Select
                className="react-select"
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: '#3082CA',
                    },
                })}
                classNamePrefix="select"
                components={animatedComponents}
                placeholder="Filter By..."
                defaultValue={defaultFilterBy.split(',').map((value) => {
                    return filterByOptions.find((option) => option.value === value);
                })}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="filterBy"
                options={filterByOptions}
                onChange={(e) => {
                    let val = e === null ? '' : e.value;
                    if (val.length) {
                        document.querySelector('.filter-by-value').classList.remove('hidden');
                        const filterByValue = document.getElementById('filterByValue').value.replace('=', '');
                        if (filterByValue.length) {
                            val += `=${filterByValue}`;
                            window.dispatchEvent(new CustomEvent('filterBy', {detail: val}));
                        } else {
                            window.dispatchEvent(new CustomEvent('filterBy', {detail: ''}));
                        }
                    } else {
                        document.querySelector('.filter-by-value').classList.add('hidden');
                        document.getElementById('filterByValue').value = '';
                        window.localStorage.setItem('filterByValue', '');
                        window.dispatchEvent(new CustomEvent('filterBy', {detail: ''}));
                    }
                    window.localStorage.setItem('filterBy', val);
                }}
            />
            <div className="filter-by-value hidden">
                <input id="filterByValue" name="filterByValue" placeholder="Value..."
                       autoCapitalize="none" autoComplete="off" autoCorrect="off"
                       spellCheck="false" type="text" aria-autocomplete="list"
                       defaultValue={defaultFilterByValue}
                       onKeyUp={(e) => {
                           if (e.key === 'Enter') {
                               e.target.blur();
                           }
                       }}
                       onBlur={(e) => {
                           let val = e.target.value;
                           window.localStorage.setItem('filterByValue', val);
                           if (val.length) {
                               const filterBy = window.localStorage.getItem('filterBy').split('=')[0];
                               val = `${filterBy}=${val}`;
                               window.localStorage.setItem('filterBy', val);
                               window.dispatchEvent(new CustomEvent('filterBy', {detail: val}));
                           } else {
                               window.dispatchEvent(new CustomEvent('filterBy', {detail: ''}));
                           }
                       }}/>
            </div>
            <label htmlFor="groupBy">Group By</label>
            <Select
                className="react-select"
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: '#3082CA',
                    },
                })}
                classNamePrefix="select"
                components={animatedComponents}
                placeholder="Group By..."
                isMulti
                defaultValue={defaultGroupBy.split(',').map((value) => {
                    return groupByOptions.find((option) => option.value === value);
                })}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                name="groupBy"
                options={groupByOptions}
                onChange={(e) => {
                    let groupBySet = [];
                    for (let i = 0; i < e.length; i++) {
                        groupBySet.push(e[i].value);
                    }
                    const groupBy = groupBySet.join(',');
                    window.localStorage.setItem('groupBy', groupBy);
                    window.dispatchEvent(new CustomEvent('groupBy', {detail: groupBy}));
                }}
            />
            <label htmlFor="days">For</label>
            <Select
                className="react-select"
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: '#3082CA',
                    },
                })}
                placeholder="Time..."
                classNamePrefix="select"
                defaultValue={dayOptions.find((option) => option.value === defaultDays)}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={false}
                name="days"
                options={dayOptions}
                onChange={(e) => {
                    window.localStorage.setItem('days', e.value);
                    window.dispatchEvent(new CustomEvent('days', {detail: parseInt(e.value)}));
                }}
            />
        </div>
    );
}

export default Controls;