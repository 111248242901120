import { Component } from 'react';

export default class Access extends Component {

  constructor (props) {
    super(props);
    this.state = {
      checked: false,
      allowed: this.props.allowed || false,
      errors: this.props.errors || []
    };
  }

  componentDidMount () {
    // Fetch the /api/access JSON endpoint to see if we have access to the API
    fetch('/api/access')
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({allowed: data.allowed || false, errors: data.errors || []});
      });
  }

  render () {
    if (this.state.checked) {
      return null;
    }
    if (this.state.allowed) {
      return (
        <div>
          <p>Active Experiments:</p>
          {this.props.children}
        </div>
      );
    }
    return (
      <div>
        <p>Access Denied</p>
        <ul>
          {this.state.errors.map((error, index) => {
            return <li key={index}>{error}</li>;
          })}
        </ul>
      </div>
    );
  }
}