// import logo from './logo.svg';
import icon from './icon.svg';
import FingerprintPage from './FingerprintPage';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import './App.css';
import Access from './Access';
import EmailPage from './EmailPage';

const HomePage = () => {
  return (
    <div className="App">
      <header className="App-header">
        {/*<img src={logo} className="App-logo" alt="logo"/>*/}
        <img src={icon} className="App-icon" alt="logo"/>
        <p>DMS Labs</p>
        <Access>
          <ol style={{
            listStyle: 'none',
            margin: 0,
            padding: 0,
          }}>
            <li>
              <Link to="/experiments/fp" className="App-link">Fingerprint Analytics</Link>
            </li>
            <li>
              <Link to="/experiments/em" className="App-link">Email Analytics</Link>
            </li>
          </ol>
        </Access>
      </header>
    </div>
  );
};

function App () {
  // Add a script tag to the bottom of the page
  if (document.getElementById('dmslabs') === null) {
    // Intentionally load script deferred and across domains in local development
    const script = document.createElement('script');
    script.src = window.location.protocol + '//' +
      window.location.host.replace('labs.dmsengage.lan:3000', 'labsdmsengage.lan:3000') +
      '/experiments/all.js';
    script.id = 'dmslabs';
    script.defer = true;
    document.body.appendChild(script);
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/experiments/fp" element={<FingerprintPage/>}/>
        <Route path="/experiments/em" element={<EmailPage/>}/>
      </Routes>
    </BrowserRouter>);
}

export default App;

